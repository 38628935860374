import _util from "util";
import _pagination from "./lib/pagination";
import _item_paginator from "./lib/item_paginator";
import _search_paginator from "./lib/search_paginator";
import _template_paginator from "./lib/template_paginator";
import _template from "./lib/template";
var exports = {};
var util = _util;
var pagination = _pagination;

_item_paginator.module(pagination, util);

_search_paginator.module(pagination, util);

_template_paginator.module(pagination, util);

_template.module(pagination, util);

exports = pagination;
export default exports;